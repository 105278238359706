import Vue from "vue";
window.Vue = require('vue');

loginUvm();
loginUvm2()

function loginUvm() {
    new Vue({
        el: "#login-uvm",
        props: {
            userType: String,
        },
        data() {
            return {
                loginExternouvm: true,
                loginInternouvm: false,
                tipocandidatouvm: false,

            }
        },
        computed: {},
        methods: {
            showLoginsUvm() {
                if (this.tipocandidatouvm == 'Externo') {
                    this.loginExternouvm = true;
                    this.loginInternouvm = false;

                } else {
                    this.loginExternouvm = false;
                    this.loginInternouvm = true;
                }
            }
        }
    });
}

function loginUvm2() {
    new Vue({
        el: "#login-uvm-2",
        props: {
            userType: String,
        },
        data() {
            return {
                loginExterno: true,
                loginInterno: false,
                tipocandidato: false,

            }
        },
        computed: {},
        methods: {
            showLogins() {
                if (this.tipocandidato == 'Externo') {
                    this.loginExterno = true;
                    this.loginInterno = false;

                } else {
                    this.loginExterno = false;
                    this.loginInterno = true;
                }
            }
        }
    });
}

